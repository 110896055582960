import React from 'react';
import { graphql } from 'gatsby';

import IndexLayout from 'layouts/IndexLayout';
import { SEO } from 'components';
import { DarkScheme } from 'helpers/navColors';
import { InnerWrap } from 'components/common/Containers/styled';

import { Body } from './styled';

const SitePagesTemplate = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <IndexLayout title={frontmatter.title} navColor={DarkScheme}>
      <SEO title={frontmatter.title} />
      <InnerWrap>
        <Body dangerouslySetInnerHTML={{ __html: html }} />
      </InnerWrap>
    </IndexLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      html
    }
  }
`;

export default SitePagesTemplate;
