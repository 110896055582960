import styled from 'styled-components';

export const Body = styled.div`
  width: 900px;
  margin: 0 auto;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 100px;

  p {
    font-size: 1.6rem;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    transform: none;
  }
`;
